import React from "react"
import cx from "classnames"

import * as s from "./Container.module.sass"

type ContainerProps = {
  className?: string
  theme?: keyof typeof themeClass
}

const themeClass = {
  default: s.default,
  small: s.small,
  middle: s.middle,
}

export const Container: React.FC<ContainerProps> = ({
  theme = "default",
  children,
  className,
}) => <div className={cx(s.root, themeClass[theme], className)}>{children}</div>
