import React from "react"
import cx from "classnames"

import { SEO } from "@components/common/SEO"
import { Header } from "@components/common/Header/Header"
import { Footer, themeClass } from "@components/common/Footer"

import "@styles/styles.sass"
import * as s from "./BaseLayout.module.sass"

export const LayoutContext = React.createContext({
  slug: "/",
  langKey: "ua",
})

type BaseLayoutProps = {
  className?: string
  theme?: keyof typeof themeClass
  pageContext: {
    slug: string
    langKey: string
  }
  location: Location
  description?: string
  meta?: HTMLMetaElement[]
  image?: string
  title: string
  noFooter?: boolean
}

export const BaseLayout: React.FC<BaseLayoutProps> = ({
  children,
  theme,
  pageContext,
  location,
  className,
  description = ``,
  meta = [],
  image,
  title,
  noFooter = false,
}) => {
  return (
    <LayoutContext.Provider
      value={{
        langKey: pageContext.langKey === "uk-UA" ? "ua" : "ru",
        slug:
          pageContext.langKey === "uk-UA"
            ? location.pathname
            : location.pathname.replace("/ru", ""),
      }}
    >
      <SEO
        title={title}
        description={description}
        meta={meta}
        image={image}
        lang={pageContext.langKey}
        location={location}
      />
      <Header />
      <main className={cx(s.root, className)}>{children}</main>
      {!noFooter && <Footer theme={theme} />}
    </LayoutContext.Provider>
  )
}
