import { useStaticQuery, graphql } from "gatsby"

export const useQueryData = () => {
  const { directus } = useStaticQuery<GatsbyTypes.FirstScreenDataQuery>(
    graphql`
      query FirstScreenData {
        directus: directus {
          items {
            contacts {
              email
              phone_number_1
              phone_number_2
            }
          }
        }
      }
    `
  )

  return directus.items.contacts
}
