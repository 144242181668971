import React from "react"
import { Link, GatsbyLinkProps } from "gatsby"
import cx from "classnames"

import * as s from "./HeaderItem.module.sass"

type HeaderItemProps = {
  className?: string
  title: string
}

export const HeaderItem: React.FC<GatsbyLinkProps<HeaderItemProps>> = ({
  className,
  to,
  children,
  title,
}) => (
  <Link
    to={to}
    className={cx(s.root, className)}
    activeClassName={s.active}
    aria-label={title}
    title={title}
  >
    {children}
  </Link>
)
