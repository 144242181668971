import React from "react"

import MeatIcon from "@icons/menu/Meat.svg"
import BreadIcon from "@icons/menu/Bread.svg"
import MilkIcon from "@icons/menu/Milk.svg"
import FishIcon from "@icons/menu/Fish.svg"
import FruitsIcon from "@icons/menu/Fruits.svg"
import FlowersIcon from "@icons/menu/Flowers.svg"
import FridgesIcon from "@icons/menu/Fridges.svg"

export const navItems = [
  {
    label: "головна",
    labelRU: "главная",
    link: "/",
  },
  {
    label: "Комплексні рішення",
    labelRU: "Комплексные решения",
    linkArray: [
      {
        label: "для м'яса",
        labelRU: "для мяса",
        link: "/meat",
        icon: <MeatIcon />,
      },
      {
        label: "для хліба",
        labelRU: "для хлеба",
        link: "/bread",
        icon: <BreadIcon />,
      },
      {
        label: "для молока",
        labelRU: "для молока",
        link: "/milk",
        icon: <MilkIcon />,
      },
      {
        label: "для риби",
        labelRU: "для рыбы",
        link: "/fish",
        icon: <FishIcon />,
      },
      {
        label: "для фруктів",
        labelRU: "для фруктов",
        link: "/fruits-n-vegetables",
        icon: <FruitsIcon />,
      },
      {
        label: "для квітів",
        labelRU: "для цветов",
        link: "/flowers",
        icon: <FlowersIcon />,
      },
      {
        label: "склянні двері",
        labelRU: "стекляные двери",
        link: "/doors",
        icon: <FridgesIcon />,
      },
    ],
  },
  {
    label: "портфоліо",
    labelRU: "портфолио",
    link: "/portfolio",
  },
  {
    label: "продукція",
    labelRU: "продукция",
    link: "/products",
  },
  {
    label: "контакти",
    labelRU: "контакты",
    link: "/contacts",
  },
]
