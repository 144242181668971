import React from "react"
import cx from "classnames"

import { Container } from "@ui/Container"
import { Row } from "@ui/Row"
import LogoIcon from "@icons/tKoseiAdditional.svg"
import WartaIcon from "@icons/Warta.svg"

import { useQueryData } from "./FooterQuery"
import * as s from "./Footer.module.sass"

type HeaderProps = {
  className?: string
  theme?: keyof typeof themeClass
}

export const themeClass = {
  primary: s.primary,
  gray: s.gray,
  blue: s.blue,
  grayCorner: s.grayCorner,
}

export const Footer: React.FC<HeaderProps> = ({
  theme = "primary",
  className,
}) => {
  const compoundClassName = cx(s.root, themeClass[theme], className)
  const {
    email,
    phone_number_1: PhoneNumber,
    phone_number_2: PhoneNumber2,
  } = useQueryData()

  return (
    <footer className={compoundClassName}>
      <Container>
        <Row className={s.row}>
          <LogoIcon className={s.logo} />
          <div className={s.info}>
            <div className={s.numbers}>
              <a className={s.link} href={`tel:${PhoneNumber}`}>
                {PhoneNumber}
              </a>
              {PhoneNumber2 && (
                <a className={s.link} href={`tel:${PhoneNumber2}`}>
                  {PhoneNumber2}
                </a>
              )}
            </div>
            <a className={cx(s.link, s.email)} href={`mailto:${email}`}>
              {email}
            </a>
          </div>
          <WartaIcon className={s.warta} />
        </Row>
      </Container>
    </footer>
  )
}
