import React, { ReactNode, useContext, useState } from "react"
import { Link } from "gatsby"
import cx from "classnames"

import { LayoutContext } from "@layouts/BaseLayout"
import ArrowBottom from "@icons/menu/ArrowBottom.svg"

import * as s from "./MenuItem.module.sass"

type MenuItemProps = {
  className?: string
  label: string
  link?: string
  linkArray?: {
    label: string
    labelRU: string
    link: string
    icon: ReactNode
  }[]
}

export const MenuItem: React.FC<MenuItemProps> = ({
  className,
  label,
  link,
  linkArray,
}) => {
  const [isOpened, setIsOpened] = useState(false)

  const context = useContext(LayoutContext)

  if (linkArray && !link) {
    return (
      <div
        className={cx(s.root, s.collapse, { [s.opened]: isOpened }, className)}
      >
        <button
          onClick={() => setIsOpened(!isOpened)}
          type="button"
          className={s.collapseButton}
        >
          {label}
          <ArrowBottom className={s.arrowIcon} />
        </button>
        <div className={s.innerLinks}>
          {linkArray.map(link => (
            <Link
              key={link.link}
              to={context.langKey === "ua" ? link.link : `/ru${link.link}`}
              className={s.linkInner}
              activeClassName={s.active}
            >
              {context.langKey === "ua" ? link.label : link.labelRU}
              <span className={s.icon}>{link.icon}</span>
            </Link>
          ))}
        </div>
      </div>
    )
  }

  return (
    <Link
      to={link as string}
      className={cx(s.root, className)}
      activeClassName={s.active}
    >
      {label}
    </Link>
  )
}
