import React, { useContext } from "react"
import cx from "classnames"

import { LayoutContext } from "@layouts/BaseLayout"
import { navItems } from "@components/common/Header/Menu/content"
import { MenuItem } from "@components/common/Header/MenuItem"

import * as s from "./Menu.module.sass"

type MenuProps = {
  hidden: boolean
  className?: string
}

export const Menu: React.FC<MenuProps> = ({ hidden, className }) => {
  const context = useContext(LayoutContext)

  return (
    <nav className={cx(s.root, className)} aria-hidden={hidden} hidden={hidden}>
      {navItems.map(element => (
        <MenuItem
          key={element.label}
          label={context.langKey === "ua" ? element.label : element.labelRU}
          link={
            element.link &&
            (context.langKey === "ua" ? element.link : `/ru${element.link}`)
          }
          linkArray={element.linkArray}
        />
      ))}
    </nav>
  )
}
