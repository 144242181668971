import React from "react"

import MeatIcon from "@icons/menu/Meat.svg"
import BreadIcon from "@icons/menu/Bread.svg"
import MilkIcon from "@icons/menu/Milk.svg"
import FishIcon from "@icons/menu/Fish.svg"
import FruitsIcon from "@icons/menu/Fruits.svg"
import FlowersIcon from "@icons/menu/Flowers.svg"
import FridgesIcon from "@icons/menu/Fridges.svg"

export const navItems = [
  {
    id: 0,
    label: "М'ясо",
    labelRU: "Мясо",
    link: "/meat",
    icon: <MeatIcon />,
  },
  {
    id: 1,
    label: "Хліб",
    labelRU: "Хлеб",
    link: "/bread",
    icon: <BreadIcon />,
  },
  {
    id: 2,
    label: "Молочна продукція",
    labelRU: "Молочная продукция",
    link: "/milk",
    icon: <MilkIcon />,
  },
  {
    id: 3,
    label: "Риба",
    labelRU: "Рыба",
    link: "/fish",
    icon: <FishIcon />,
  },
  {
    id: 4,
    label: "Фрукти",
    labelRU: "Фрукты",
    link: "/fruits-n-vegetables",
    icon: <FruitsIcon />,
  },
  {
    id: 5,
    label: "Квіти",
    labelRU: "Цветы",
    link: "/flowers",
    icon: <FlowersIcon />,
  },
  {
    id: 6,
    label: "Склянні двері",
    labelRU: "Стеклянные двери",
    link: "/doors",
    icon: <FridgesIcon />,
  },
]
