// extracted by mini-css-extract-plugin
export const root = "Footer-module--root--3-fYg";
export const primary = "Footer-module--primary--3wNjF";
export const grayCorner = "Footer-module--grayCorner--2X9Je";
export const blue = "Footer-module--blue--V5FNE";
export const gray = "Footer-module--gray--1oC_S";
export const row = "Footer-module--row--3fiuW";
export const logo = "Footer-module--logo--2DmDW";
export const info = "Footer-module--info--2s2rv";
export const numbers = "Footer-module--numbers--1PUPZ";
export const link = "Footer-module--link--1wZBC";
export const email = "Footer-module--email--heU4m";
export const warta = "Footer-module--warta--3nTtP";