// extracted by mini-css-extract-plugin
export const root = "Header-module--root--212Wp";
export const nav = "Header-module--nav--1RQ1V";
export const menu = "Header-module--menu--2cEs9";
export const logo = "Header-module--logo--Q_6My";
export const menuButton = "Header-module--menuButton--IxXvc";
export const active = "Header-module--active--aGymi";
export const menuLine = "Header-module--menuLine--2dQmJ";
export const menuNav = "Header-module--menuNav--3hOHr";
export const opened = "Header-module--opened--rvf-2";
export const language = "Header-module--language--14K7M";