import React, { useContext } from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import { LayoutContext } from "@layouts/BaseLayout"

// @ts-ignore
import defaultOpenGraphImage from "../../../images/og_image.jpg"

type SEOProps = {
  description?: string
  lang?: string
  meta?: HTMLMetaElement[]
  image?: string
  location?: Location
  title: string
}

export const SEO: React.FC<SEOProps> = ({
  description = ``,
  lang = `uk-UA`,
  meta = [],
  image,
  location,
  title,
}) => {
  const { site } = useStaticQuery<GatsbyTypes.SEOQuery>(
    graphql`
      query SEO {
        site {
          siteMetadata {
            title
            description
            descriptionRU
            author
            siteUrl
          }
        }
      }
    `
  )

  const { langKey, slug } = useContext(LayoutContext)

  const metaDescription =
    description ||
    (lang === "uk-UA"
      ? site?.siteMetadata?.description
      : site?.siteMetadata?.descriptionRU)
  const defaultTitle = site?.siteMetadata?.title
  const ogImageUrl =
    `${site?.siteMetadata?.siteUrl}${image}` || `${site?.siteMetadata?.siteUrl}${defaultOpenGraphImage}`

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : ""}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site?.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: ogImageUrl,
        },
        {
          property: `twitter:image`,
          content: ogImageUrl,
        },
        {
          property: `image`,
          content: ogImageUrl,
        },
      ].concat(meta)}
    >
      <link
        rel="alternate"
        hrefLang={lang === "uk-UA" ? "ru-RU" : "uk-UA"}
        href={
          lang === "uk-UA"
            ? `${location?.origin}/ru${slug}`
            : `${location?.origin}${slug}`
        }
      />
    </Helmet>
  )
}
