import React, { useContext, useState } from "react"
import { Link } from "gatsby"
import cx from "classnames"

import { LayoutContext } from "@layouts/BaseLayout"
import { Container } from "@ui/Container"
import { Row } from "@ui/Row"
import { HeaderItem } from "@components/common/Header/HeaderItem"
import { navItems } from "@components/common/Header/Header/content"
import { Menu } from "@components/common/Header/Menu"
import LogoIcon from "@icons/menu/tKosei.svg"
import RussianIcon from "@icons/menu/Russian.svg"
import UkrainianIcon from "@icons/menu/Ukrainian.svg"

import * as s from "./Header.module.sass"

type HeaderProps = {
  className?: string
}

export const Header: React.FC<HeaderProps> = ({ className }) => {
  const [isOpenedMenu, setIsOpenedMenu] = useState(false)

  const context = useContext(LayoutContext)

  return (
    <header className={cx(s.root, className)}>
      <Container>
        <Row>
          <div className={s.menu}>
            <button
              type="button"
              className={cx(s.menuButton, { [s.active]: isOpenedMenu })}
              onClick={() => setIsOpenedMenu(!isOpenedMenu)}
              aria-label="Меню"
            >
              <span className={s.menuLine} />
              <span className={s.menuLine} />
              <span className={s.menuLine} />
            </button>
            {context.langKey === "ua" ? (
              <Link
                to={`/ru${context.slug}`}
                className={s.language}
                aria-label="Русский"
              >
                <RussianIcon />
              </Link>
            ) : (
              <Link
                to={context.slug}
                className={s.language}
                aria-label="Українська"
              >
                <UkrainianIcon />
              </Link>
            )}
            <Menu
              className={cx(s.menuNav, { [s.opened]: isOpenedMenu })}
              hidden={!isOpenedMenu}
            />
          </div>
          <nav className={s.nav}>
            {navItems.map(link => (
              <HeaderItem
                key={link.id}
                to={context.langKey === "ua" ? link.link : `/ru${link.link}`}
                title={context.langKey === "ua" ? link.label : link.labelRU}
              >
                {link.icon}
              </HeaderItem>
            ))}
          </nav>
          <div className={s.logo}>
            <Link
              to={context.langKey === "ua" ? "/" : `/ru/`}
              aria-label="Главная"
            >
              <LogoIcon />
            </Link>
          </div>
        </Row>
      </Container>
    </header>
  )
}
